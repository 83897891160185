import { Cart, CartTransaction, ShopProduct } from "@/lib/types/shop-types";
import { create } from "zustand";

type UpdateType = "plus-unit" | "plus-case" | "minus-unit" | "minus-case" | "delete";
type ProductSubscriber = (transaction: CartTransaction | undefined) => void;

interface CartStore {
    cart: Cart | undefined;
    subscribers: Map<number, Set<ProductSubscriber>>;
    updateCartItem: (productID: number, updateType: UpdateType, promotionID: number | null) => void;
    addCartItem: (
        shopProduct: ShopProduct,
        updateType: UpdateType,
        promotionID: number | null
    ) => void;
    subscribeToProduct: (productID: number, callback: ProductSubscriber) => () => void;
    initialize: (initialCart: Cart | undefined) => void;
}

// Utility functions remain the same
function updateCartItem(
    cartTransactions: CartTransaction,
    updateType: UpdateType
): CartTransaction | null {
    if (updateType === "delete") return null;

    let newQuantity = cartTransactions.numUnits;

    switch (updateType) {
        case "plus-unit":
            newQuantity = cartTransactions.numUnits + 1;
            break;
        case "plus-case":
            newQuantity =
                cartTransactions.numUnits + cartTransactions.shopProduct.wholesaleUnitsPerCase;
            break;
        case "minus-unit":
            newQuantity = cartTransactions.numUnits - 1;
            break;
        case "minus-case":
            newQuantity =
                cartTransactions.numUnits - cartTransactions.shopProduct.wholesaleUnitsPerCase;
            break;
        default:
            break;
    }

    if (newQuantity === 0) return null;

    return {
        ...cartTransactions,
        numUnits: newQuantity,
        extPrice: newQuantity * cartTransactions.unitPrice
    };
}

function createOrUpdateCartTrans(
    existingItem: CartTransaction | undefined,
    shopProduct: ShopProduct,
    updateType: UpdateType,
    promotionID: number | null
): CartTransaction {
    if (existingItem) {
        const updatedLine = updateCartItem(existingItem, updateType);
        if (updatedLine) {
            return updatedLine;
        }
        throw new Error("could not update existing item, this is unexpected");
    }

    const newCartLine: CartTransaction = {
        cartTransactionID: null,
        productID: shopProduct.productID,
        manualPromotionID: promotionID,
        quantityPromotionID: null,
        shopProduct,
        numUnits: 0,
        unitPrice: shopProduct.price!.unitPrice,
        extPrice: 0,
        discount: 0
    };

    const updatedCartLine = updateCartItem(newCartLine, updateType);

    if (!updatedCartLine) {
        throw new Error("Failed to create new cart item");
    }

    return updatedCartLine;
}

function updateCartTotals(
    transactions: CartTransaction[]
): Pick<
    Cart,
    "totalNumUnits" | "totalCases" | "totalFullPrice" | "totalDiscount" | "totalExtPrice"
> {
    const totalNumUnits = transactions.reduce((sum, item) => sum + item.numUnits, 0);
    const totalCases = transactions.reduce(
        (sum, item) => sum + item.numUnits / item.shopProduct.wholesaleUnitsPerCase,
        0
    );
    const totalDiscount = transactions.reduce((sum, item) => sum + item.discount, 0);
    const totalExtPrice = transactions.reduce((sum, item) => sum + item.extPrice, 0);
    const totalFullPrice = transactions.reduce(
        (sum, item) => sum + item.unitPrice * item.numUnits,
        0
    );

    return {
        totalNumUnits,
        totalCases,
        totalExtPrice,
        totalDiscount,
        totalFullPrice
    };
}

function createEmptyCart(): Cart {
    return {
        cartID: null,
        status: "InProgress",
        customerID: 0,
        totalNumUnits: 0,
        totalCases: 0,
        transactions: [],
        totalDiscount: 0,
        totalExtPrice: 0,
        totalFullPrice: 0,
        pricingLastUpdated: new Date("1/1/1970")
    };
}

export const useCartStore = create<CartStore>()((set, get) => ({
    cart: undefined,
    subscribers: new Map(),

    initialize: (initialCart) => {
        set({ cart: initialCart });

        // If there's an initial cart, notify all subscribers of their relevant transactions
        if (initialCart) {
            console.log("Initial cart set, notifying subscribers...");
            // Get all unique product IDs from the cart
            const productIDs = new Set(initialCart.transactions.map((t) => t.productID));

            // For each product ID, notify its subscribers if there is a transaction for that product
            productIDs.forEach((productID) => {
                const subscribers = get().subscribers.get(productID);
                if (subscribers) {
                    // only include transactions without manual pricing
                    const transaction = initialCart.transactions.find(
                        (t) => t.productID === productID && t.manualPromotionID === null
                    );
                    if (transaction) {
                        subscribers.forEach((callback) => callback(transaction));
                    }
                }
            });
        } else {
            // If initialCart is undefined, notify all subscribers with undefined
            get().subscribers.forEach((subscribers, productID) => {
                subscribers.forEach((callback) => callback(undefined));
            });
        }
    },

    updateCartItem: (productID, updateType, promotionID) => {
        const currentCart = get().cart || createEmptyCart();
        const updatedTransactions = currentCart.transactions
            .map((trans) =>
                trans.productID === productID && trans.manualPromotionID === promotionID
                    ? updateCartItem(trans, updateType)
                    : trans
            )
            .filter(Boolean) as CartTransaction[];

        const newCart = {
            ...currentCart,
            ...updateCartTotals(updatedTransactions),
            transactions: updatedTransactions
        };

        set({ cart: newCart });

        // Notify subscribers
        const subscribers = get().subscribers.get(productID);
        if (subscribers) {
            const transaction = newCart.transactions.find((t) => t.productID === productID);
            subscribers.forEach((callback) => callback(transaction));
        }
    },

    addCartItem: (shopProduct, updateType, promotionID) => {
        const currentCart = get().cart || createEmptyCart();

        const existingItem = currentCart.transactions.find(
            (item) =>
                item.shopProduct.productID === shopProduct.productID &&
                item.manualPromotionID === promotionID
        );

        const updatedItem = createOrUpdateCartTrans(
            existingItem,
            shopProduct,
            updateType,
            promotionID
        );

        const updatedTransactions = existingItem
            ? currentCart.transactions.map((item) =>
                  item.shopProduct.productID === updatedItem.shopProduct.productID
                      ? updatedItem
                      : item
              )
            : [...currentCart.transactions, updatedItem];

        const newCart = {
            ...currentCart,
            ...updateCartTotals(updatedTransactions),
            transactions: updatedTransactions
        };

        set({ cart: newCart });

        // Notify subscribers
        const subscribers = get().subscribers.get(shopProduct.productID);
        if (subscribers) {
            const transaction = newCart.transactions.find(
                (t) => t.productID === shopProduct.productID
            );
            subscribers.forEach((callback) => callback(transaction));
        }
    },

    subscribeToProduct: (productID, callback) => {
        const store = get();

        // Get or create subscriber set for this product
        if (!store.subscribers.has(productID)) {
            store.subscribers.set(productID, new Set());
        }
        const productSubscribers = store.subscribers.get(productID)!;

        // Add new subscriber
        productSubscribers.add(callback);

        // Instead of immediate notification, just return the transaction
        // Let the component handle the initial state
        const transaction = store.cart?.transactions.find((t) => t.productID === productID);
        callback(transaction);

        // Return cleanup function
        return () => {
            const currentStore = get();
            const currentProductSubscribers = currentStore.subscribers.get(productID);
            if (currentProductSubscribers) {
                currentProductSubscribers.delete(callback);
                if (currentProductSubscribers.size === 0) {
                    currentStore.subscribers.delete(productID);
                }
            }
        };
    }
}));
