"use client";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select";
import { CartTransaction, ShopProduct } from "@/lib/types/shop-types";
import { useCartStore } from "@/stores/cart-store";
import { updateItemQuantityInDB } from "components/cart/actions";
import { Minus, Plus } from "lucide-react";
import { useEffect, useState, useTransition } from "react";
import { useFormState } from "react-dom";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

function SubmitButton({
    type,
    onClick,
    disabled = false
}: {
    type: "plus-case" | "minus-case" | "plus-unit" | "minus-unit";
    onClick: (e: React.MouseEvent) => void;
    disabled?: boolean;
}) {
    const ariaLabel = {
        "plus-case": "Increase case quantity",
        "minus-case": "Decrease case quantity",
        "plus-unit": "Increase unit quantity",
        "minus-unit": "Decrease unit quantity"
    }[type];

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
    };

    return (
        <Button
            variant="outline"
            size="icon"
            onClick={handleClick}
            className="h-8 w-8"
            aria-label={ariaLabel}
            disabled={disabled}
        >
            {type.includes("plus") ? <Plus className="h-3 w-3" /> : <Minus className="h-3 w-3" />}
        </Button>
    );
}

export default function EditQuantityDialog({
    shopProduct,
    showPromotions
}: {
    shopProduct: ShopProduct;
    showPromotions: boolean;
}) {
    const { subscribeToProduct, updateCartItem } = useCartStore();
    const [message, formAction] = useFormState(updateItemQuantityInDB, null);
    const [isPending, startTransition] = useTransition();
    const [transaction, setTransaction] = useState<CartTransaction | undefined>();
    const [selectedPromotionID, setSelectedPromotion] = useState<number | null>(null);
    const [localQuantity, setLocalQuantity] = useState({
        cases: 0,
        units: 0
    });

    useEffect(() => {
        const unsubscribe = subscribeToProduct(shopProduct.productID, (cartTransaction) => {
            if (!cartTransaction || cartTransaction.manualPromotionID === null) {
                setTransaction(cartTransaction);
                setSelectedPromotion(cartTransaction?.manualPromotionID || null);
                if (cartTransaction) {
                    setLocalQuantity({
                        cases: Math.floor(
                            cartTransaction.numUnits / shopProduct.wholesaleUnitsPerCase
                        ),
                        units: cartTransaction.numUnits % shopProduct.wholesaleUnitsPerCase
                    });
                } else {
                    setLocalQuantity({ cases: 0, units: 0 });
                }
            }
        });

        return unsubscribe;
    }, [shopProduct.productID, shopProduct.wholesaleUnitsPerCase, subscribeToProduct]);

    const handleQuantityChange =
        (type: "cases" | "units") => (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            e.stopPropagation();
            const value = parseInt(e.target.value);
            if (!isNaN(value) && value >= 0) {
                setLocalQuantity((prev) => ({
                    ...prev,
                    [type]: value
                }));
            }
        };

    const handlePromotionChange = (value: string) => {
        setSelectedPromotion(value === "none" ? null : parseInt(value));
    };

    const handleQuantityUpdate = async (
        type: "plus-case" | "minus-case" | "plus-unit" | "minus-unit"
    ) => {
        let totalUnits =
            localQuantity.cases * shopProduct.wholesaleUnitsPerCase + localQuantity.units;
        let newQuantity = totalUnits;
        let newCases = localQuantity.cases;
        let newUnits = localQuantity.units;

        switch (type) {
            case "plus-case":
                newQuantity += shopProduct.wholesaleUnitsPerCase;
                newCases += 1;
                break;
            case "minus-case":
                if (newCases > 0) {
                    newQuantity -= shopProduct.wholesaleUnitsPerCase;
                    newCases -= 1;
                }
                break;
            case "plus-unit":
                newQuantity += 1;
                newUnits += 1;
                break;
            case "minus-unit":
                if (newUnits > 0) {
                    newQuantity -= 1;
                    newUnits -= 1;
                } else if (newCases > 0) {
                    newCases -= 1;
                    newUnits = shopProduct.wholesaleUnitsPerCase - 1;
                    newQuantity -= 1;
                }
                break;
        }

        setLocalQuantity({
            cases: newCases,
            units: newUnits
        });

        const payload = {
            productID: shopProduct.productID,
            quantity: Math.max(0, newQuantity),
            promotionID: selectedPromotionID
        };

        startTransition(() => {
            updateCartItem(payload.productID, type, payload.promotionID);
        });

        await formAction(payload);
    };

    const totalUnits =
        localQuantity.cases * shopProduct.wholesaleUnitsPerCase + localQuantity.units;

    return (
        <div className="w-full max-w-[180px]" onClick={(e) => e.stopPropagation()}>
            <div className="flex flex-col gap-3">
                {showPromotions &&
                    shopProduct.price?.promotions &&
                    shopProduct.price.promotions.length > 0 && (
                        <div className="flex flex-col gap-1">
                            <Label className="text-sm">Promotion</Label>
                            <Select
                                value={"none"}
                                onValueChange={handlePromotionChange}
                                disabled={isPending}
                            >
                                <SelectTrigger className="h-8 text-sm">
                                    <SelectValue placeholder="Select promotion" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value={"none"}>No promotion</SelectItem>
                                    {shopProduct.price.promotions.map((promotion) => (
                                        <SelectItem
                                            key={promotion.promotionID}
                                            value={promotion.promotionID.toString()}
                                        >
                                            {promotion.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    )}

                <div className="flex items-center gap-2">
                    <Label className="min-w-12 text-sm">Cases</Label>
                    <div className="flex items-center gap-1">
                        <SubmitButton
                            type="minus-case"
                            onClick={() => handleQuantityUpdate("minus-case")}
                            disabled={localQuantity.cases === 0 || isPending}
                        />
                        <Input
                            type="number"
                            value={localQuantity.cases}
                            onChange={handleQuantityChange("cases")}
                            className="h-8 w-14 px-1 text-center text-sm"
                            min="0"
                            onClick={(e) => e.stopPropagation()}
                            disabled={isPending}
                        />
                        <SubmitButton
                            type="plus-case"
                            onClick={() => handleQuantityUpdate("plus-case")}
                            disabled={isPending}
                        />
                    </div>
                </div>

                {!shopProduct.sellByCaseOnly ? (
                    <div className="flex items-center gap-2">
                        <Label className="min-w-12 text-sm">Units</Label>
                        <div className="flex items-center gap-1">
                            <SubmitButton
                                type="minus-unit"
                                onClick={() => handleQuantityUpdate("minus-unit")}
                                disabled={totalUnits === 0 || isPending}
                            />
                            <Input
                                type="number"
                                value={localQuantity.units}
                                onChange={handleQuantityChange("units")}
                                className="h-8 w-14 px-1 text-center text-sm"
                                min="0"
                                onClick={(e) => e.stopPropagation()}
                                disabled={isPending}
                            />
                            <SubmitButton
                                type="plus-unit"
                                onClick={() => handleQuantityUpdate("plus-unit")}
                                disabled={isPending}
                            />
                        </div>
                    </div>
                ) : null}
            </div>

            <p aria-live="polite" className="sr-only" role="status">
                {message}
            </p>
        </div>
    );
}
